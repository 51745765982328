<template>
  <v-row class="mt-0">
    <v-col class="yield mx-3 pt-0">
      <report-list v-if="showDashboard" />

      <v-row
        v-else
        class="yield-report-content"
      >
        <v-col class="yield-report">
          <data-table
            ref="table"
            :actions="actions"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from '../components/mixin'

const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Yield',
  components: {
    DataTable: () => import('@/tools/components/table/DataTable'),
    ReportList: () => import('@/dashboard/components/ReportList'),
  },
  mixins: [mixin],
  data() {
    return {
      actions: [],
    }
  },
  computed: {
    ...mapToolsState({
      items: (state) => state.items,
      selectedSearch: (state) => state.selectedSearch,
    }),
    showDashboard() {
      return this.selectedSearch?.dataType === 'custom'
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.yield {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  .loader {
    padding: 64px;
    align-self: center;
  }
}

.yield-report-content {
  position: relative;
}

::v-deep.filter-link {
  margin-top: 8px;
}

::v-deep.filter-content {
  margin-top: 16px;
}

::v-deep.filter-label {
  padding-top: 20px;
}

</style>
