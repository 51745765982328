import mixin from 'src/utils/mixin'

import DataTable from 'src/tools/components/table/DataTable'

export default {
  components: {
    DataTable,
  },
  ...mixin,
}
